import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const AIMachineLearning = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="Metaverse"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">Predictive analysis introduces processes that use historical data, statistical algorithms, and machine learning techniques to predict future outcomes. It uses statistical modelling, and machine learning techniques and analyses large datasets to identify functional relationships for making predictions.</p>
                        <p className="mt-2">
                        TEQT uses Predictive analysis to forecast business-based scenarios for different Customers and as an effective tool for fraud detection and risk profiling.
                        </p>

                        <h6>Key components include:</h6>

                        <ul className="circle-list">
                            <li className="mt-2"> <span className="text-black">Data quality and management:</span>TEQT ensures high-quality accurate and consistent data through data management processes and data governance frameworks.</li>
                            <li className="mt-2"><span className="text-black">Scalability: </span>TEQT has the right infrastructure and technology to handle large volumes of data required for analysis.</li>
                            <li className="mt-2"><span className="text-black"> Ethical considerations:</span>TEQT ensures predictive analysis solutions adhere to ethical standards and comply with relevant regulations.</li>
                        </ul>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query AIMachineLearningPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

AIMachineLearning.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default AIMachineLearning;
